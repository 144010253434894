<template>
  <div id="wrapper" class="app">
    <Nav />
    <h1 class="title-outlined-text centered-content">Aero Estate</h1>
    <img class="background-image" style="position: fixed; top: 0; left: 0;" src="@/assets/img/background.jpeg" alt="">

    <div class="page container-fluid pt-3">
      <NuxtPage />
    </div>
  </div>

  <div v-if="runTimeConfig.public.isLoading">
    <Spinner />
  </div>
</template>

<script setup>
let runTimeConfig = useRuntimeConfig();
</script>

<style scoped>
.page {
  color: black;
}

.header {
  background-color: rgb(81, 104, 141, 0.4) !important;
  z-index:  1 !important;
}

.background-image {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  object-fit: cover;
  background-color: rgba(0,0,0,0.5); /* Adjust the alpha value to control the transparency of the overlay */
  z-index: -5;
}
.plane-image {
  position: relative;
  top: -10px;
  right: -50px;
  transform: scale(1.3);
  width: 80%;
  z-index: 0;
}

h1 {
  font-size: 75px;
}
h5 {
  font-size: 35px;
}



a{
  text-decoration: none;
}

</style>
